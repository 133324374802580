
.ql-editor {
  background: #fffcf5;
}
#quill-container {
  height: 300px;
}
.ql-container .ql-editor {
  min-height: 20em;
  padding-bottom: 1em;
  max-height: 25em;
}
