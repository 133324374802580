
.pagination {
    font-size: 17px;
}
.pagination a {
    color: black !important;
}
.pagination .router-link-active,
.pagination .router-link-active:hover {
    background: #c71a1a;
    color: white !important;
}
