.btn-success {
  background: $color-green;
  border-color: $color-green;

  &:hover,
  &:focus,
  &:active {
    background: $color-green-active !important;
    border-color: $color-green-active !important;
  }
}
