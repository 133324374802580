@mixin sidebar-background-color($background-color, $font-color) {
  &:after,
  &:before {
    background-color: $background-color;
  }

  #style-3::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px $background-color;
    background-color: $background-color;
  }

  #style-3::-webkit-scrollbar
  {
    width: 6px;
    background-color: $font-color;
  }

  #style-3::-webkit-scrollbar-thumb
  {
    background-color: $background-color;
  }


  .logo {
    border-bottom: 0px solid rgba($font-color, .3);

    p {
      color: $font-color;
    }

    .simple-text {
      color: $font-color;
    }
  }

  .sidebar-nav {
    li:not(.active) {
      > a {
        color: $font-color;
      }
    }
    .divider {
      background-color: rgba($font-color, .2);
    }

  }

}

@mixin sidebar-active-color($font-color) {
  .sidebar-nav {
    li {
      &.active > a {
        color: $font-color;
        opacity: 1;
      }
    }
  }
}
