.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  .sidebar-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 260px;
    z-index: 4;
    box-shadow: inset -1px 0px 0px 0px $medium-gray;
  }
  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

}

.sidebar,
.off-canvas-sidebar {
  width: 260px;
  display: block;
  font-weight: 200;

  .logo {
    padding: 5px 20px;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      line-height: 20px;
    }

    .simple-text {
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-base;
      text-align: center;
      font-weight: $font-weight-bold;
      line-height: 40px;
      text-align: left;

      .logo-img {
          margin-left: 0px;
          margin-right: 5px;
          text-align: center;
      }
    }
  }

  .sidebar-nav {
    //margin-top: 20px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    & > li {
      position: relative;
      display: block;
    }

    li {
      > a {
        opacity: .7;
        position: relative;
        display: block;
        padding: 10px 15px;
      }

      &:hover > a {
        opacity: 1;
      }

      &.active {
        background: #293848;
        box-shadow: inset -1px 0px 0px 0px #DDDDDD;
      }

      &.active > a {
        color: $primary-color;
        opacity: 1;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }

    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: $white-background-color;
  }

  &,
  &[data-background-color="white"] {
    @include sidebar-background-color($white-background-color, $default-color);
  }
  &[data-background-color="black"] {
    @include sidebar-background-color($black-background-color, $white-color);
  }
  &[data-background-color="darkblue"] {
    @include sidebar-background-color($darkblue-background-color, $white-color);
  }

  &[data-active-color="primary"] {
    @include sidebar-active-color($primary-color);
  }
  &[data-active-color="info"] {
    @include sidebar-active-color($info-color);
  }
  &[data-active-color="success"] {
    @include sidebar-active-color($success-color);
  }
  &[data-active-color="warning"] {
    @include sidebar-active-color($warning-color);
  }
  &[data-active-color="danger"] {
    @include sidebar-active-color($danger-color);
  }

}

.main-panel {
  position: relative;
  z-index: 2;
  float: right;
  width: $sidebar-width;
  min-height: 100%;

  > .content {
    padding: 30px 15px;
    min-height: calc(100% - 123px);
  }

  > .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar {
    margin-bottom: 0;
  }
}

.sidebar,
.main-panel {
  overflow: auto;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: .2s, .2s;
  transition-duration: .2s, .2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  position: absolute;
  overflow: hidden;
  overflow-y: hidden;
}
