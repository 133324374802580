.form-group {
  min-height: 50px;
  margin-bottom: 1.5em;

  &.has-error {
    .input-group-text {
      border-color: $danger-color;
      border-width: 2px;
    }

    &.input-group .form-control {
      border-left-color: $danger-color;
    }
  }
}

.has-error {
  .form-control {
    border-color: $danger-color;
  }

  .help-block {
    color: $danger-color;
    font-size: 14px;
  }
}
