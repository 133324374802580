
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity .1s;
  transition: opacity .1s
}
.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}
