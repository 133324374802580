/*!

 =========================================================
 * Paper Dashboard - v1.1.2
 =========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2017 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "paper/variables";
@import "paper/mixins";
@import "paper/typography";
// Core CSS
@import "paper/misc";
@import "paper/sidebar-and-main-panel";
@import "paper/buttons";
@import "paper/inputs";
@import "paper/alerts";
@import "paper/tables";
@import "paper/checkbox-radio";
@import "paper/navbars";
@import "paper/forms";
@import "paper/footers";
// Fancy Stuff
@import "paper/dropdown";
@import "paper/cards";
@import "paper/auth";
@import "paper/responsive";




