@media (max-width: 767px) {
  .navbar .navbar-brand {
    margin: 0;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
  .main-panel .navbar {
    display: none;
  }
  .navbar {
    min-height: 75px;
  }
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-search-form {
    display: none;
  }
  .navbar-nav > li > .dropdown-menu,
  .dropdown .dropdown-menu {
    transform: translate3d(0px, -40px, 0px);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
  }
  .navbar-nav > li.open > .dropdown-menu, .dropdown.open .dropdown-menu {
    transform: translate3d(0px, 0px, 0px);
  }

  .navbar-nav > li > .dropdown-menu:before {
    border-bottom: 11px solid $medium-pale-bg;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    top: -11px;
  }
  .navbar-nav > li > .dropdown-menu:after {
    border-bottom: 11px solid $pale-bg;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    top: -10px;
  }

  .navbar-nav.navbar-left > li > .dropdown-menu:before {
    right: auto;
    left: 12px;
  }

  .navbar-nav.navbar-left > li > .dropdown-menu:after {
    right: auto;
    left: 12px;
  }

  .navbar {
    .navbar-header {
      margin-left: 10px;
    }
  }

  .footer:not(.footer-big) {
    nav > ul {
      li:first-child {
        margin-left: 0;
      }
    }
  }

  body .navbar-collapse.collapse {
    display: none !important;
  }

  .card {
    form {
      [class*="col-"] {
        padding: 6px;
      }
      [class*="col-"]:first-child {
        padding-left: 15px;
      }
      [class*="col-"]:last-child {
        padding-right: 15px;
      }
    }
  }
}

/*          Changes for small display      */

@media (max-width: 991px) {
  .sidebar {
    display: none;
  }

  .main-panel {
    width: 100%;
  }
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }
  body {
    position: relative;
  }
  h6 {
    font-size: 1em;
  }
  .wrapper {
    @include transform-translate-x(0px);
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    left: 0;
    background-color: white;
  }
  .navbar .container {
    left: 0;
    width: 100%;
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    position: relative;
  }
  .navbar .navbar-right-menu,
  .navbar .navbar-collapse.collapse,
  .navbar .navbar-collapse.collapse.in,
  .navbar .navbar-collapse.collapsing {
    display: none !important;
  }

  .navbar-nav > li {
    float: none;
    position: relative;
    display: block;
  }

  .off-canvas-sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 230px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0px;
    padding-left: 0;

    @include transform-translate-x(230px);
    @include transition (0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .sidebar-wrapper {
      position: relative;
      z-index: 3;
      overflow-y: scroll;
      height: 100%;
      box-shadow: inset 1px 0px 0px 0px $medium-gray;
    }

    .nav {
      margin-top: 0;
      padding: 10px $margin-base-vertical 0;

      > li {

        > a {
          margin: 0px 0px;
          color: $default-color;
          text-transform: uppercase;
          font-weight: 600;
          font-size: $font-size-small;
          line-height: $line-height-general;
          padding: 10px 0;

          &:hover,
          &.active {
            color: $default-states-color;
          }

          p,
          .notification,
          .caret
          {
            display: inline-block;
          }

          .caret {
            float: right;
            position: relative;
            top: 12px;
          }

          i {
            font-size: 18px;
            margin-right: 10px;
            line-height: 26px;
          }
        }

        &.active > a {

          &:before {
            border-right: none;
            border-left: 12px solid $medium-gray;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            right: auto;
            margin-left: -$margin-base-vertical;
            left: 0px;
            top: 10px;
          }

          &:after {
            border-right: none;
            border-left: 12px solid $bg-nude;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            right: auto;
            margin-left: -$margin-base-vertical;
            left: -1px;
            top: 10px;
          }
        }

      }

    }

    &::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: $bg-nude;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
      display: block;
      content: "";
      z-index: 1;
    }
    &.has-image::after {
      @include black-filter(.8);
    }

    .logo {
      position: relative;
      z-index: 4;
      padding-top: 11px;
      padding-bottom: 11px;
    }

    .divider {
      height: 1px;
      margin: 10px 0;
    }
  }
  .main-panel.push-left{
    right:230px;
  }
  .nav-open .navbar-collapse {
    @include transform-translate-x(0px);
  }
  .nav-open .navbar .container {
    left: -230px;
  }
  .nav-open .wrapper {
    left: 0;
    @include transform-translate-x(-230px);
  }
  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto;
  }

  .navbar-header .navbar-toggle {
    margin: 10px 15px 10px 0;
    width: 40px;
    height: 40px;
  }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .dropdown-menu .divider {
    background-color: rgba(229, 229, 229, 0.15);
  }

  .navbar-nav {
    margin: 1px 0;
  }

  .dropdown-menu {
    display: none;

    & > li > a {
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .navbar-fixed-top {
    backface-visibility: hidden;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
  .form-control + .form-control-feedback {
    margin-top: -8px;
  }
  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important;
  }
  .btn.dropdown-toggle {
    margin-bottom: 0;
  }
  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px;
  }
  .media-post .media-body {
    width: 100%;
  }

  .navbar-collapse.collapse {
    height: 100% !important;
  }
  .navbar-collapse.collapse.in {
    display: block;
  }
  .navbar-header .collapse, .navbar-toggle {
    display: block !important;
  }
  .navbar-header {
    float: none;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-panel > .content {
    padding-left: 0;
    padding-right: 0;
  }
  .nav .open > a {
    &,
    &:focus,
    &:hover {
      background-color: transparent;
    }

  }

  .footer .copyright {
    padding: 0px 15px;
    width: 100%;
  }
}

//overwrite table responsive for 768px screens

@media (min-width: 992px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px;
  }
  .table-responsive {
    overflow: visible;
  }

}

@media (max-width: 991px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #dddddd;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }

}
