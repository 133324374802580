
.single-post p {
    font-size: 18px;
    line-height: 28px;
}
.back-to-blog {
    text-transform: uppercase;
    font-size: 10px;
    color: #c71a1a;
}
.back-to-blog:hover {
    text-decoration: none;
    color: black;
}
.post-meta {
    color: grey;
}
