
.posts > .post {
    padding-bottom: 60px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 60px;
}
.posts > .post:last-of-type {
    border-bottom: none;
    margin-bottom: 20px;
}
.table {
  margin-bottom: 0;
}
