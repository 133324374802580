/*      light colors - used for select dropdown         */
.fade-enter-active[data-v-12245fb5],
.fade-leave-active[data-v-12245fb5] {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
.fade-enter[data-v-12245fb5],
.fade-leave-to[data-v-12245fb5] {
  opacity: 0;
}
.alert[data-v-12245fb5] {
  border: 0;
  border-radius: 0;
  color: #FFFFFF;
  padding: 10px 15px;
  font-size: 14px;
  z-index: 100;
  display: inline-block;
  position: fixed;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.alert.center[data-v-12245fb5] {
    left: 0px;
    right: 0px;
    margin: 0 auto;
}
.alert.left[data-v-12245fb5] {
    left: 20px;
}
.alert.right[data-v-12245fb5] {
    right: 20px;
}
.container .alert[data-v-12245fb5] {
    border-radius: 4px;
}
.navbar .alert[data-v-12245fb5] {
    border-radius: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 85px;
    width: 100%;
    z-index: 3;
}
.navbar:not(.navbar-transparent) .alert[data-v-12245fb5] {
    top: 70px;
}
.alert .alert-icon[data-v-12245fb5] {
    font-size: 30px;
    margin-right: 5px;
}
.alert .close ~ span[data-v-12245fb5] {
    display: block;
    max-width: 89%;
}
.alert[data-notify="container"][data-v-12245fb5] {
    width: 350px;
    padding: 10px 10px 10px 20px;
    border-radius: 4px;
}
.alert.alert-with-icon[data-v-12245fb5] {
    padding-left: 65px;
}
.alert-info[data-v-12245fb5] {
  background-color: #7CE4FE;
  color: #3091B2;
}
.alert-success[data-v-12245fb5] {
  background-color: #8EF3C5;
  color: #229863;
}
.alert-warning[data-v-12245fb5] {
  background-color: #FFE28C;
  color: #BB992F;
}
.alert-danger[data-v-12245fb5] {
  background-color: #FF8F5E;
  color: #B33C12;
}
